import {
    ClusterConfiguratorFormValues,
    AddClusterNodeConfigFormValues,
    ClusterConfigurator,
} from '../ClusterConfigurator';
import merge from 'deepmerge';
import { CcClusterType } from '../../../../services/models/CcCluster';
import { TopologyItem } from '../../../Topology/TopologyItem';

export interface AddMongoClusterNodeConfigFormValues
    extends AddClusterNodeConfigFormValues {
    serverPort?: number;
    serverDataDirectory?: string;
    configurationTemplate?: string;
    adminUser?: string;
    adminPassword?: string;
    replicaSetName?: boolean;
    mongoAuthDb?: string;
}

export interface MongoFormValues extends ClusterConfiguratorFormValues {
    nodeConfig: AddMongoClusterNodeConfigFormValues;
    arbiterNode?: {
        [key: string]: string;
    };
}
export default class MongoConfigurator extends ClusterConfigurator {
    public static getDefaults(): MongoFormValues {
        return merge(ClusterConfigurator.getDefaults(), {
            nodeConfig: {
                serverPort: 27017,
                serverDataDirectory: '/var/lib/mongodb',
                replicaSetName: 'my_mongodb_0',
                mongoAuthDb: 'admin',
            },
            arbiterNode: {},
        });
    }

    public static getVendors() {
        return [
            {
                name: 'Percona',
                value: 'percona',
                versions: [
                    { name: '6.0', value: '6.0' },
                    { name: '5.0', value: '5.0' },
                    { name: '4.4', value: '4.4' },
                    { name: '4.2', value: '4.2' },
                ],
                icon: 'img-logo-percona.svg',
            },
            {
                name: 'MongoDB',
                value: '10gen',
                versions: [
                    { name: '6.0', value: '6.0' },
                    { name: '5.0', value: '5.0' },
                    { name: '4.4', value: '4.4' },
                ],
                icon: 'img-logo-mongodb.svg',
            },
            {
                name: 'MongoDB Enterprise',
                value: 'mongodbenterprise',
                versions: [
                    { name: '6.0', value: '6.0' },
                    { name: '5.0', value: '5.0' },
                    { name: '4.4', value: '4.4' },
                ],
                icon: 'img-logo-mongodb.svg',
            },
        ];
    }

    public static getJobData(formValues: MongoFormValues): any {
        const {
            topology,
            nodeConfig,
            topologyDataIps,
            arbiterNode,
        } = formValues;
        return merge(ClusterConfigurator.getJobData(formValues), {
            cluster_type: CcClusterType.TYPE_MONGODB.toLowerCase(),
            db_user: nodeConfig.adminUser,
            db_password: nodeConfig.adminPassword,
            datadir: nodeConfig.serverDataDirectory,
            port: nodeConfig.serverPort,
            config_template: nodeConfig.configurationTemplate,
            mongodb_authdb: nodeConfig.mongoAuthDb,
            mongos_conf_template: 'mongos.conf.org',
            mongos_servers: [],
            config_servers: [],
            replica_sets: [
                {
                    rs: nodeConfig.replicaSetName,
                    members: topology.map((node: TopologyItem, index) => {
                        const member = {
                            port: nodeConfig.serverPort,
                            hostname: node.extraData.hostname,
                            hostname_data: node.extraData.hostname,
                            hostname_internal:
                                topologyDataIps?.[node.key] || '',
                            arbiter_only:
                                arbiterNode?.[node.extraData.hostname] || false,
                        };
                        if (index > 0) {
                            return {
                                ...member,
                                priority: '1.0',
                                slave_delay: '0',
                                hidden: false,
                            };
                        }
                        return member;
                    }),
                },
            ],
        });
    }

    public static getNodeConfigurationValidate(): string[][] {
        return [
            ['nodeConfig', 'serverPort'],
            ['nodeConfig', 'adminUser'],
            ['nodeConfig', 'adminPassword'],
            ['nodeConfig', 'replicaSetName'],
        ];
    }
}
