import React, { useEffect } from 'react';
import StatisticBadge, {
    StatisticBadgeProps,
} from '../../common/StatisticBadge';
import { CcAlarmSeverity } from '../../services/models/CcAlarm';
import {
    getAlarmSeverityFormatText,
    getAlarmSeverityFormatType,
} from './AlarmSeverityFormat';
import useCurrentAlarmsList from './useCurrentAlarmsList';

export default AlarmsBadge;

export type AlarmsBadgeProps = StatisticBadgeProps & {
    severity?: CcAlarmSeverity;
    clusterId?: number;
};

function AlarmsBadge({ severity, clusterId, ...rest }: AlarmsBadgeProps) {
    const { statsRecord, refresh } = useCurrentAlarmsList({
        useGlobalState: !clusterId,
    });

    useEffect(() => {
        (async () => {
            if (clusterId) {
                await refresh({ cluster_id: clusterId });
            }
        })();
    }, [clusterId]);

    const alarmCount = statsRecord?.getStatsCount(severity) || 0;

    return (
        <StatisticBadge
            count={alarmCount}
            dot={false}
            title={`${alarmCount}${
                severity
                    ? ` ${getAlarmSeverityFormatText(
                          severity
                      ).toLocaleLowerCase()}`
                    : ''
            } alarms`}
            status={getAlarmSeverityFormatType(
                severity || CcAlarmSeverity.ALARM_CRITICAL
            )}
            {...rest}
        />
    );
}
