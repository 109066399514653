
import CmonRequestService, {CmonRequestServiceResponseData} from './CmonRequestService';
import {CmonJobInstanceProps} from './models/CmonJobInstance'
import { CmonJobInstanceCommand } from './models/CmonJobInstance';


export interface CreateJobInstanceRequestData {
    cluster_id?: number;
    job?: CmonJobInstanceProps | {job_spec: string;};
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface UpdateJobInstanceRequestData {
    cluster_id?: number;
    job?: CmonJobInstanceProps | {job_spec: string;};
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface DeleteJobInstanceRequestData {
    job_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface KillJobInstanceRequestData {
    job_id?: number;
    request_created?: string;
    request_id?: number;
    signal?: number;
    [key: string]: any;
}

export interface CloneJobInstanceRequestData {
    cluster_id?: number;
    job_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetJobInstanceRequestData {
    job_id?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetJobInstancesRequestData {
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}

export interface GetJobLogRequestData {
    ascending?: boolean;
    job_id?: number;
    limit?: number;
    log_level?: string;
    offset?: number;
    request_created?: string;
    request_id?: number;
    [key: string]: any;
}


export default class CmonJobsService extends CmonRequestService {
    
    public static module(): string {
        return 'jobs';
    }
    
    public static async createJobInstance(
        data: CreateJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('createJobInstance', data, opts);
    }

    public static async updateJobInstance(
        data: UpdateJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('updateJobInstance', data, opts);
    }

    public static async deleteJobInstance(
        data: DeleteJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('deleteJobInstance', data, opts);
    }

    public static async killJobInstance(
        data: KillJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('killJobInstance', data, opts);
    }

    public static async cloneJobInstance(
        data: CloneJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('cloneJobInstance', data, opts);
    }

    public static async getJobInstance(
        data: GetJobInstanceRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getJobInstance', data, opts);
    }

    public static async getJobInstances(
        data: GetJobInstancesRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getJobInstances', data, opts);
    }

    public static async getJobLog(
        data: GetJobLogRequestData,
        opts?: any
    ): Promise<CmonRequestServiceResponseData> {
        return await this.doRequest('getJobLog', data, opts);
    }

    public static async createStartJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.START};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStartinitialJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STARTINITIAL};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStopJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STOP};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRestartJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RESTART};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createReloadJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RELOAD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRebootJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REBOOT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStartClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.START_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStopClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STOP_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createBootstrapClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.BOOTSTRAP_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createFindMostAdvancedJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.FIND_MOST_ADVANCED};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createAddnodeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ADDNODE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createAddShardJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ADD_SHARD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRemoveShardJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REMOVE_SHARD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createReplsetStepDownJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REPLSET_STEP_DOWN};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createReplsetFreezeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REPLSET_FREEZE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createAddReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ADD_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStageReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STAGE_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createFailoverReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.FAILOVER_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStopReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STOP_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStartReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.START_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPromoteReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PROMOTE_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createResetReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RESET_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDemoteNodeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DEMOTE_NODE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDelayReplicationSlaveJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELAY_REPLICATION_SLAVE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createToggleReplicationSyncJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.TOGGLE_REPLICATION_SYNC};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createMakeReplicationMasterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MAKE_REPLICATION_MASTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableBinaryLoggingJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_BINARY_LOGGING};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableBinaryLoggingJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_BINARY_LOGGING};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableLogArchivingJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_LOG_ARCHIVING};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableLogArchivingJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_LOG_ARCHIVING};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableClusterReadonlyJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_CLUSTER_READONLY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableClusterReadonlyJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_CLUSTER_READONLY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableDbReadonlyJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_DB_READONLY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableDbReadonlyJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_DB_READONLY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEncryptReplicationJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENCRYPT_REPLICATION};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupSslJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_SSL};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRemovenodeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REMOVENODE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createGarbdJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.GARBD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createHaproxyJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.HAPROXY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createMaxscaleJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MAXSCALE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createProxysqlJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PROXYSQL};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createKeepalivedJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.KEEPALIVED};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPgbouncerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PGBOUNCER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPgbackrestJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PGBACKREST};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPbmagentJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PBMAGENT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createNfsclientJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.NFSCLIENT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createNfsserverJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.NFSSERVER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRegisternodeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REGISTERNODE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createImportConfigJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.IMPORT_CONFIG};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createExportConfigJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.EXPORT_CONFIG};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createUpgradeClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.UPGRADE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStopstartClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STOPSTART_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRollingRestartJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ROLLING_RESTART};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSaveClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SAVE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRestoreClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RESTORE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSaveControllerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SAVE_CONTROLLER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRestoreControllerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RESTORE_CONTROLLER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createBackupJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.BACKUP};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeleteBackupJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELETE_BACKUP};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRunJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RUN};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCloneClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CLONE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEmailJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.EMAIL};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createReportJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REPORT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRestoreBackupJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.RESTORE_BACKUP};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createVerifyBackupJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.VERIFY_BACKUP};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeleteOldBackupsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELETE_OLD_BACKUPS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeleteAllBackupsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELETE_ALL_BACKUPS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createUploadBackupDataToCloudStorageJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.UPLOAD_BACKUP_DATA_TO_CLOUD_STORAGE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDownloadBackupDataFromCloudStorageJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DOWNLOAD_BACKUP_DATA_FROM_CLOUD_STORAGE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCloudBackupUploadJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CLOUD_BACKUP_UPLOAD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCloudBackupDownloadJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CLOUD_BACKUP_DOWNLOAD};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeployAgentsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DEPLOY_AGENTS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createConfigureAgentsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CONFIGURE_AGENTS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createFailoverJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.FAILOVER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createAddClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ADD_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRemoveClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.REMOVE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupServerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_SERVER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createConvertToShardedClusterJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CONVERT_TO_SHARDED_CLUSTER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDoCheckJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DO_CHECK};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCollectLogsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.COLLECT_LOGS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateLocalRepositoryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_LOCAL_REPOSITORY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createUpdateLocalRepositoryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.UPDATE_LOCAL_REPOSITORY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createMigrateDataJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MIGRATE_DATA};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createImportCertificatesJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.IMPORT_CERTIFICATES};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createMigrateBackupsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MIGRATE_BACKUPS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createMigrateBackupschedulesJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MIGRATE_BACKUPSCHEDULES};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createErrorReportJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ERROR_REPORT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createFailJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.FAIL};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSuccessJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SUCCESS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateContainerServerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_CONTAINER_SERVER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateContainerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_CONTAINER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateContainersJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_CONTAINERS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStopContainerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.STOP_CONTAINER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createStartContainerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.START_CONTAINER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeleteContainerJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELETE_CONTAINER};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPitrJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PITR};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupAuditLoggingJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_AUDIT_LOGGING};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupTdeJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_TDE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupExtensionJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_EXTENSION};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCalculateDbGrowthJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CALCULATE_DB_GROWTH};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateReplicationLinkJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_REPLICATION_LINK};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDestroyReplicationLinkJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DESTROY_REPLICATION_LINK};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCmonHaCreateSnapshotJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CMON_HA_CREATE_SNAPSHOT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createInspectHostJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.INSPECT_HOST};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createManageProcessesJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.MANAGE_PROCESSES};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPurgeOldDataJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PURGE_OLD_DATA};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createPurgeQueryMonitorJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.PURGE_QUERY_MONITOR};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createRotateLogsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ROTATE_LOGS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCreateMaintenanceJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CREATE_MAINTENANCE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableRecoveryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_RECOVERY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableRecoveryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_RECOVERY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDisableNodeRecoveryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DISABLE_NODE_RECOVERY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createEnableNodeRecoveryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.ENABLE_NODE_RECOVERY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createClusterRecoveryJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CLUSTER_RECOVERY};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createCheckPkgUpgradesJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.CHECK_PKG_UPGRADES};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSyncClustersJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SYNC_CLUSTERS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeployCmonagentsJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DEPLOY_CMONAGENTS};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createUninstallCmonagentJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.UNINSTALL_CMONAGENT};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createDeleteDatabaseJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.DELETE_DATABASE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

    public static async createSetupLogrotateJobInstance(
        clusterId: number, 
        spec: any = {}, 
        extra: CreateJobInstanceRequestData = {}
    ): Promise<CmonRequestServiceResponseData> {
        const jobSpec = {...spec, command: CmonJobInstanceCommand.SETUP_LOGROTATE};
        return await this.createJobInstance({
            ...extra, 
            cluster_id: clusterId, 
            job: { class_name: 'CmonJobInstance', ...extra.job, job_spec: JSON.stringify(jobSpec)}
        });
    }

}
