import React, { useEffect } from 'react';
import StatisticBadge, {
    StatisticBadgeProps,
} from '../../common/StatisticBadge';
import useRunningJobList from './useRunningJobList';

export default JobsBadge;

export type JobsBadgeProps = StatisticBadgeProps & {
    clusterId?: number;
};

function JobsBadge({ clusterId, status, ...rest }: JobsBadgeProps) {
    const { statsRecord, refresh } = useRunningJobList({
        useGlobalState: !clusterId,
    });

    useEffect(() => {
        (async () => {
            if (clusterId) {
                await refresh({ cluster_id: clusterId });
            }
        })();
    }, [clusterId]);

    const jobCount = statsRecord?.getStatsCount(status);

    return (
        <StatisticBadge
            count={jobCount}
            dot={false}
            status={status || 'info'}
            title={`${jobCount} running jobs`}
            {...rest}
        />
    );
}
