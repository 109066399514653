import { useCallback } from 'react';
import useFetch, { FetchOptions } from '../useFetch';
import CmonDiscoveryService from '../../services/cmon/CmonDiscoveryService';

export type UseCheckHostProps = {
    sshConfig?: {
        sshKeyPath?: string;
        sshUser?: string;
        sshSudoPassword?: string;
    };
    clusterId?: number;
    throwCancel?: boolean;
};
export default function useCheckHost({
    sshConfig,
    clusterId,
    throwCancel,
}: UseCheckHostProps) {
    const { data, refresh, cancel } = useFetch({
        name: 'check-available-host',
        useCache: false,
        throwError: true,
        throwCancel,
        fetchFn: async ({ hosts }, { requestId }) => {
            const { checked_hosts } = await CmonDiscoveryService.checkHosts(
                {
                    nodes: hosts.map((hostname: string) => ({
                        class_name: 'CmonHost',
                        hostname,
                    })),
                    ...(sshConfig && {
                        sudo_password: sshConfig.sshSudoPassword,
                        ssh_credentials: {
                            class_name: 'CmonSshCredentials',
                            key_file: sshConfig.sshKeyPath,
                            user_name: sshConfig.sshUser,
                        },
                    }),
                    ...(clusterId && {
                        cluster_id: clusterId,
                    }),
                },
                { requestId }
            );
            return checked_hosts;
        },
        cancelFn: async ({ requestId }) => {
            await CmonDiscoveryService.cancelRequest(requestId);
        },
    });
    const check = useCallback(
        async (hosts: string[], ops?: FetchOptions) => {
            return await refresh({ hosts }, ops);
        },
        [refresh]
    );
    return {
        checkedHosts: data,
        check,
        cancel,
    };
}
