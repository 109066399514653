import React, { useMemo } from 'react';
import Form, { FormInstance, FormItemProps } from 'antd/lib/form';
import { NamePath } from 'rc-field-form/lib/interface';
import { getObjectValueByPath } from '../objectHelpers';

export default AppFormItem;

export type AppFormItemProps = FormItemProps & {
    onChange?: (value: any) => void;
    onUpdate?: (form: FormInstance) => void;
    updateOnCurrent?: boolean;
    updateOnFields?: NamePath[];
    shouldUpdateProps?: FormItemProps;
};

function AppFormItem({
    children,
    shouldUpdate,
    name,
    onChange,
    onUpdate,
    updateOnCurrent = false,
    updateOnFields,
    shouldUpdateProps = { noStyle: true },
    ...rest
}: AppFormItemProps) {
    const thisShouldUpdate =
        shouldUpdate ||
        onChange ||
        (updateOnCurrent && !!name) ||
        updateOnFields;

    const shouldUpdateValue = useMemo(() => {
        if (thisShouldUpdate) {
            return (prev: any, next: any, info: any) => {
                let check = false;
                const currentChanged =
                    !!name && !areFieldsEqual(prev, next, name);
                if (currentChanged) {
                    onChange?.(getByPath(name, next));
                }
                if (updateOnCurrent && currentChanged) {
                    check = true;
                }

                if (!check && updateOnFields) {
                    check = updateOnFields.some(
                        (field) => !areFieldsEqual(prev, next, field)
                    );
                }

                if (typeof shouldUpdate === 'function') {
                    if (check) {
                        shouldUpdate(prev, next, info);
                    } else {
                        check = shouldUpdate(prev, next, info);
                    }
                }

                return check;
            };
        }
        return false;
    }, [shouldUpdate, onChange, updateOnCurrent, updateOnFields]);

    return shouldUpdateValue === false ? (
        <Form.Item name={name} {...rest}>
            {children}
        </Form.Item>
    ) : (
        <Form.Item shouldUpdate={shouldUpdateValue} {...shouldUpdateProps}>
            {(form: FormInstance) => {
                onUpdate?.(form);
                return (
                    <Form.Item name={name} {...rest}>
                        {typeof children === 'function'
                            ? children(form)
                            : children}
                    </Form.Item>
                );
            }}
        </Form.Item>
    );

    function getByPath(path: NamePath, obj: any) {
        return getObjectValueByPath(
            (typeof path === 'string' ? [path] : path) as string[],
            obj
        );
    }
    function areFieldsEqual(a: any, b: any, field: NamePath) {
        return getByPath(field, a) === getByPath(field, b);
    }
}
