import { CmonFeature } from '../cmon/CmonFeature';

export enum CcLicenseType {
    Demo = 'Demo',
    Advanced = 'Advanced',
    Enterprise = 'Enterprise',
    Community = 'Community',
}

const LICENSE_DEMO_EMAIL = 'demo@severalnines.com';
const LICENSE_NODE_LIMIT_PERCENT = 90;
const LICENSE_EXPIRE_SOON_DAYS = 14;

export enum CcLicenseState {
    NODE_LIMIT_CLOSE = 'node_limit_close',
    EXPIRE_SOON = 'expire_soon',
    NODE_LIMIT_EXCEED = 'exceed',
    EXPIRED = 'expired',
    TRIAL = 'trial',
    COMMUNITY = 'community',
}

export enum CcLicenseFeature {
    FULL_REPOSITORIES = 'FULL_REPOSITORIES',
    ADD_NODE = 'ADD_NODE',
    PROMOTE_SLAVE = 'PROMOTE_SLAVE',
}

export type CcLicenseFeatureType = CcLicenseFeature | CmonFeature;

export type CcLicenseProps = {
    company?: string;
    days_left?: number;
    email_address?: string;
    expiration_date?: string;
    licensed_nodes?: number;
    type?: string;
    used_nodes?: number;
    valid_date?: boolean;
};

export default class CcLicense {
    public readonly company?: string;
    public readonly daysLeft?: number;
    public readonly emailAddress?: string;
    public readonly expirationDate?: string;
    public readonly licensedNodes?: number;
    public readonly type?: CcLicenseType;
    public readonly usedNodes?: number;
    public readonly validDate?: boolean;
    public readonly isActive?: boolean;
    public readonly state?: CcLicenseState[];

    constructor(props: CcLicenseProps) {
        this.company = props.company;
        this.daysLeft = props.days_left;
        this.emailAddress = props.email_address;
        this.expirationDate = props.expiration_date;
        this.licensedNodes = props.licensed_nodes;
        this.type = props.type as CcLicenseType;
        this.usedNodes = props.used_nodes;
        this.validDate = props.valid_date;
        this.state = [];
        this.isActive = true;

        // Expired
        if (this.expirationDate && !this.validDate) {
            this.state.push(CcLicenseState.EXPIRED);
            this.isActive = false;
        }

        // All available nodes are used
        if (
            this.usedNodes &&
            this.licensedNodes &&
            this.licensedNodes === this.usedNodes
        ) {
            this.state.push(CcLicenseState.NODE_LIMIT_EXCEED);
        }

        // Setting status in order
        // 90% nodes are used - set status NODE_LIMIT_CLOSE
        if (
            this.isActive &&
            !this.state.includes(CcLicenseState.NODE_LIMIT_EXCEED) &&
            this.usedNodes &&
            this.licensedNodes
        ) {
            const percent = (this.usedNodes * 100) / this.licensedNodes;
            if (percent >= LICENSE_NODE_LIMIT_PERCENT) {
                this.state.push(CcLicenseState.NODE_LIMIT_CLOSE);
            }
        }
        // less than LICENSE_EXPIRE_SOON_DAYS days left
        if (
            !this.state.includes(CcLicenseState.EXPIRED) &&
            this.type !== CcLicenseType.Community &&
            this.expirationDate &&
            this.daysLeft !== undefined &&
            this.daysLeft < LICENSE_EXPIRE_SOON_DAYS
        ) {
            this.state.push(CcLicenseState.EXPIRE_SOON);
        }

        // set type to Demo or Community
        if (
            (this.emailAddress === LICENSE_DEMO_EMAIL &&
                this.type === CcLicenseType.Enterprise) ||
            this.type === CcLicenseType.Demo
        ) {
            this.type = CcLicenseType.Demo;
            if (this.state.includes(CcLicenseState.EXPIRED)) {
                this.type = CcLicenseType.Community;
            }
        }

        if (this.type === CcLicenseType.Demo) {
            this.state.push(CcLicenseState.TRIAL);
        } else if (this.type === CcLicenseType.Community) {
            this.state.push(CcLicenseState.COMMUNITY);
            this.state = this.state.filter(
                (state) => state !== CcLicenseState.EXPIRED
            );
        }
    }
}
