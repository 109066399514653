import React from 'react';
import './FormItem.less';
import { Form } from 'antd';
import { FormItemProps as AntFormItemProps } from 'antd/es';
import classNames from 'classnames';

export default FormItem;

export type FormItemProps = AntFormItemProps & {
    withPadding?: boolean;
    withLessMarginBottom?: boolean;
};

function FormItem({
    className,
    withPadding,
    withLessMarginBottom,
    ...rest
}: FormItemProps) {
    return (
        <Form.Item
            className={classNames('FormItem', className, {
                'FormItem--withPadding': withPadding,
                'FormItem--withLessMarginBottom': withLessMarginBottom,
            })}
            {...rest}
        />
    );
}
