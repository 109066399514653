import React, { Fragment } from 'react';
import TypographyText from '../../common/TypographyText';
import CcNode, { CcNodeRole, CcNodeType } from '../../services/models/CcNode';
import NodeFormat from './NodeFormat';
import { Space } from 'antd';
import { collectionKeyValueSort } from '../../common/sorting';
import { getNodeRoleText, getNodeTypeText } from './NodeTypeFormat';
import CcCluster from '../../services/models/CcCluster';
import { SpaceProps } from 'antd/lib/space';

export default NodeListInline;

export type NodeListInlineProps = {
    cluster: CcCluster;
    size?: SpaceProps['size'];
};

function NodeListInline({ cluster, size = [30, 8] }: NodeListInlineProps) {
    const nodeTypeGroup = cluster.getNodesGroupedByTypeWithNoneRole();
    const nodeRoleGroup = cluster.getNodesGroupedByRole(true);
    const groups: any[] = collectionKeyValueSort(
        [
            ...Object.keys(nodeTypeGroup).map((k) => ({
                type: k,
                text: getNodeTypeText(k),
                nodes: nodeTypeGroup[k as CcNodeType],
            })),
            ...Object.keys(nodeRoleGroup).map((k) => ({
                type: k,
                text: getNodeRoleText(k),
                nodes: nodeRoleGroup[k as CcNodeRole],
            })),
        ],
        'type',
        [
            CcNodeRole.CONTROLLER,
            CcNodeRole.MASTER,
            CcNodeRole.MULTI,
            CcNodeRole.INTERMEDIATE,
            CcNodeRole.SLAVE,
        ]
    );
    return (
        <Space size={size} style={{ minWidth: '350px' }} wrap>
            {groups.map((g, gIndex) => (
                <Fragment>
                    <Space key={g.type}>
                        <TypographyText muted>{g.text}:</TypographyText>
                        {g.nodes.map((n: CcNode, index: number) => (
                            <Space size={size} key={n.uniqueId || index}>
                                <NodeFormat
                                    node={n}
                                    showPopover={true}
                                    showText={false}
                                    showStatus={true}
                                    showType={false}
                                />
                            </Space>
                        ))}
                    </Space>
                </Fragment>
            ))}
        </Space>
    );
}
