import AppDivider from '../common/AppDivider';
import DebugAddNodeModal from './DebugAddNodeModal';
import DebugAppFormItem from './DebugAppFormItem';
import DebugAppProgress from './DebugAppProgress';
import DebugBackupCloudUploadModal from './DebugBackupCloudUploadModal';
import DebugBackupFormat from './DebugBackupFormat';
import DebugBackupSettingsButton from './DebugBackupSettingsButton';
import DebugBackupWizardButton from './DebugBackupWizardButton';
import DebugBackupWizardButtonNoElastic from './DebugBackupWizardButtonNoElastic';
import DebugCloudCredentialsModal from './DebugCloudCredentialsModal';
import DebugClusterActionsMenu from './DebugClusterActionsMenu';
import DebugClusterCloneModal from './DebugClusterCloneModal';
import DebugClusterDashboardVariables from './DebugClusterDashboardVariables';
import DebugClusterFormat from './DebugClusterFormat';
import DebugClusterList from './DebugClusterList';
import DebugClusterPageMenu from './DebugClusterPageMenu';
import DebugClusterTopology from './DebugClusterTopology';
import DebugClusterTopologySmall from './DebugClusterTopologySmall';
import DebugCreateServiceButton from './DebugCreateServiceButton';
import DebugHostCheckInput from './DebugHostCheckInput';
import DebugIcons from './DebugIcons';
import DebugInstallBackupTool from './DebugInstallBackupTool';
import DebugJobProgress from './DebugJobProgress';
import DebugLdapSettingsButton from './DebugLdapSettingsButton';
import DebugModalDefault from './DebugModalDefault';
import DebugMonitoringActionMenu from './DebugMonitoringActionMenu';
import DebugMonitorToolbar from './DebugMonitorToolbar';
import DebugMonitorVariable from './DebugMonitorVariable';
import DebugNodeActionsMenu from './DebugNodeActionsMenu';
import DebugNodeFormat from './DebugNodeFormat';
import DebugNodesInput from './DebugNodesInput';
import DebugNodeTree from './DebugNodeTree';
import DebugNotifications from './DebugNotifications';
import DebugPromoCard from './DebugPromoCard';
import DebugRepositoryInput from './DebugRepositoryInput';
import DebugSandbox from './DebugSandbox';
import DebugSquareStatusBadge from './DebugSquareStatusBadge';
import DebugSshCheckableNodesInput from './DebugSshCheckableNodesInput';
import DebugStatisticBadge from './DebugStatisticBadge';
import DebugStatusFormat from './DebugStatusFormat';
import DebugTimeRange from './DebugTimeRange';
import DebugTmp from './DebugTmp';
import DebugUseFeatureCheck from './DebugUseFeatureCheck';
import DebugUseFetch from './DebugUseFetch';
import DebugCurrentUserMenu from './DebugCurrentUserMenu';
import React, { useEffect } from 'react';
import useClusterList from '../components/Clusters/useClusterList';
import useRunningJobList from '../components/Jobs/useRunningJobList';
import useServiceMock from './serviceMock/useServiceMock';
import { Link, useParams } from 'react-router-dom';
import { ServiceMethod } from './serviceMock/ServiceMockContext';
import DebugUserGroupCreateButton from './DebugUserGroupCreateButton';
import DebugBackupScheduleList from './DebugBackupScheduleList';
import DebugClusterListMini from './DebugClusterListMini';
import DebugNodeList from './DebugNodeList';
import DebugReportPage from './DebugReportPage';
import DebugLdapMapGroupButton from './DebugLdapMapGroupButton';
import DebugLdapGroupMapActionMenu from './DebugLdapGroupMapActionMenu';
import DebugClusterListReadonly from './DebugClusterListC2CReadonly';
import DebugAppTable from './DebugAppTable';

export default DebugComponents;

const components: { [key: string]: React.ReactElement } = {
    AddNodeModal: <DebugAddNodeModal />,
    AppFormItem: <DebugAppFormItem />,
    AppProgress: <DebugAppProgress />,
    AppTable: <DebugAppTable />,
    BackupCloudUploadModal: <DebugBackupCloudUploadModal />,
    BackupFormat: <DebugBackupFormat />,
    BackupInstallTool: <DebugInstallBackupTool />,
    BackupSettingsButton: <DebugBackupSettingsButton />,
    BackupWizardButton: <DebugBackupWizardButton />,
    BackupWizardButtonNoElastic: <DebugBackupWizardButtonNoElastic />,
    BackupScheduleList: <DebugBackupScheduleList />,
    CloudCredentialsModal: <DebugCloudCredentialsModal />,
    ClusterActionsMenu: <DebugClusterActionsMenu />,
    ClusterCloneModal: <DebugClusterCloneModal />,
    ClusterDashboardVariables: <DebugClusterDashboardVariables />,
    ClusterFormat: <DebugClusterFormat />,
    ClusterList: <DebugClusterList />,
    DebugClusterListReadonly: <DebugClusterListReadonly />,
    ClusterPageMenu: <DebugClusterPageMenu />,
    ClusterTopology: <DebugClusterTopology />,
    ClusterTopologySmall: <DebugClusterTopologySmall />,
    CreateServiceButton: <DebugCreateServiceButton />,
    HostCheckInput: <DebugHostCheckInput />,
    ClusterListMini: <DebugClusterListMini />,
    icons: <DebugIcons />,
    JobProgress: <DebugJobProgress />,
    LdapGroupMapActionMenu: <DebugLdapGroupMapActionMenu />,
    LdapSettingsButton: <DebugLdapSettingsButton />,
    LdapMapGroupButton: <DebugLdapMapGroupButton />,
    ModalDefault: <DebugModalDefault />,
    MonitorSettingsMenu: <DebugMonitoringActionMenu />,
    MonitorToolbar: <DebugMonitorToolbar />,
    MonitorVariable: <DebugMonitorVariable />,
    NodeActionsMenu: <DebugNodeActionsMenu />,
    NodeFormat: <DebugNodeFormat />,
    NodeList: <DebugNodeList />,
    NodesInput: <DebugNodesInput />,
    NodeTree: <DebugNodeTree />,
    Notifications: <DebugNotifications />,
    PromoCard: <DebugPromoCard />,
    RepositoryInput: <DebugRepositoryInput />,
    ReportPage: <DebugReportPage />,
    Sandbox: <DebugSandbox />,
    SquareStatusBadge: <DebugSquareStatusBadge />,
    SshCheckableNodesInput: <DebugSshCheckableNodesInput />,
    StatisticBadge: <DebugStatisticBadge />,
    StatusFormat: <DebugStatusFormat />,
    TimeRange: <DebugTimeRange />,
    Tmp: <DebugTmp />,
    useFeatureCheck: <DebugUseFeatureCheck />,
    useFetch: <DebugUseFetch />,
    CurrentUserMenu: <DebugCurrentUserMenu />,
    UserGroupCreateButton: <DebugUserGroupCreateButton />,
};

export type DebugComponentsProps = {};

function DebugComponents() {
    const { componentName } = useParams<{ componentName: string }>();
    useServiceMock({
        mockId: 'root',
        methods: [
            ServiceMethod.DO_REQUEST,
            ServiceMethod.CREATE_JOB_INSTANCE,
            ServiceMethod.CHECK_HOSTS,
            ServiceMethod.GET_SNAPSHOT_REPOSITORIES,
            ServiceMethod.GET_ALL_CLUSTER_INFO,
            ServiceMethod.GET_CONFIG,
            ServiceMethod.GET_JOB_INSTANCES,
            ServiceMethod.GET_JOB_INSTANCE,
            ServiceMethod.CLOUD_LIST_CREDENTIALS,
            ServiceMethod.CLOUD_PROXY,
            ServiceMethod.EXECUTE_SCRIPT,
            ServiceMethod.GET_CONFIG_GROUPED,
            ServiceMethod.SET_CONFIG,
            ServiceMethod.CREATE_ENABLE_LOG_ARCHIVING,
            ServiceMethod.LIST_ERROR_REPORTS,
            ServiceMethod.REMOVE_ERROR_REPORT,
        ],
    });

    const { refresh: refreshRunningJobs } = useRunningJobList();
    useEffect(() => {
        (async () => {
            await refreshRunningJobs();
        })();
    }, [refreshRunningJobs]);

    const { refresh } = useClusterList();
    useEffect(() => {
        (async () => {
            await refresh();
        })();
    }, [refresh]);

    return (
        <div className="DebugComponents" style={{ padding: '40px' }}>
            {!componentName &&
                Object.keys(components).map((name) => (
                    <div key={name}>
                        <Link to={`/debugComponents/${name}`}>{name}</Link>
                    </div>
                ))}
            {componentName ? (
                <div>
                    <Link to={`/debugComponents`}>Back</Link>
                    <AppDivider />
                    {components[componentName] ? (
                        components[componentName]
                    ) : (
                        <div>Component not found</div>
                    )}
                </div>
            ) : null}
        </div>
    );
}
