import CcLicense, {
    CcLicenseFeature,
    CcLicenseFeatureType,
    CcLicenseType,
} from './CcLicense';
import CcLicenseCheck from './CcLicenseCheck';
import { getCmonLicenseFeatures } from '../cmon/CmonLicenseFeature';
import { CcClusterType, getTechnology } from './CcCluster';

export const LICENSED_FEATURES = [
    CcLicenseFeature.FULL_REPOSITORIES,
    ...getCmonLicenseFeatures(),
];

export interface CcLicenseInfoProps {
    license: CcLicense;
    licenseCheck: CcLicenseCheck;
}

export default class CcLicenseInfo {
    public readonly license: CcLicense;
    public readonly licenseCheck: CcLicenseCheck;

    constructor(props: CcLicenseInfoProps) {
        this.license = props.license;
        this.licenseCheck = props.licenseCheck;
    }

    isFeatureEnabled(feature: CcLicenseFeatureType): boolean {
        if (
            !this.licenseCheck.hasLicense ||
            !this.license.type ||
            !this.license.isActive
        ) {
            return false;
        }
        return isFeatureEnabled(this.license.type, feature);
    }
}

export function isFeatureEnabled(
    type: CcLicenseType,
    feature: CcLicenseFeatureType
): boolean {
    return !!getFeatureMatrix()[type]?.includes(feature);
}

export function getFeatureMatrix(): {
    [key in CcLicenseType]?: CcLicenseFeatureType[];
} {
    let matrix: { [key in CcLicenseType]?: CcLicenseFeatureType[] } = {};
    matrix[CcLicenseType.Demo] = [...LICENSED_FEATURES];
    matrix[CcLicenseType.Advanced] = [...LICENSED_FEATURES];
    matrix[CcLicenseType.Enterprise] = [...LICENSED_FEATURES];
    return matrix;
}

/**
 * Some features are clusterType and tech related
 * e.g. REPLICATION_PROMOTE_SLAVE = 'REPLICATION/PROMOTE_SLAVE',
 * or POSTGRESQL_PROMOTE_SLAVE = 'POSTGRESQL/PROMOTE_SLAVE',
 *
 * This method will check if passed `${feature}` is on the checking list
 * Then check `${type}/${feature}`
 * Then check `${getTechnology(type)}/${feature}`
 */
export function getClusterFeature(
    feature: CcLicenseFeatureType,
    type?: CcClusterType
) {
    if (
        type &&
        ([
            CcLicenseFeature.ADD_NODE,
            CcLicenseFeature.PROMOTE_SLAVE,
        ] as CcLicenseFeatureType[]).includes(feature)
    ) {
        let newFeature = `${type}/${feature}` as CcLicenseFeatureType;
        if (LICENSED_FEATURES.includes(newFeature)) {
            return newFeature;
        }

        newFeature = `${getTechnology(
            type
        )?.toUpperCase()}/${feature}` as CcLicenseFeatureType;
        if (LICENSED_FEATURES.includes(newFeature)) {
            return newFeature;
        }
    }

    if (LICENSED_FEATURES.includes(feature)) {
        return feature;
    }

    return undefined;
}
