import React, { useMemo } from 'react';
import AppPageWrapper, {
    AppPageWrapperProps,
} from '../../common/Layout/AppPageWrapper';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CcCluster, {
    CcClusterTechnology,
} from '../../services/models/CcCluster';
import DbGrowthPage from './DbGrowthPage';
import QueryMonitorPage from '../QueryMonitor/QueryMonitorPage';
import AgentQueryMonitorPage from '../QueryMonitor/AgentQueryMonitorPage';
import BetaRibbon from '../../common/Feedback/BetaRibbon';
import { ClusterConfigProvider } from '../Clusters/Config/ClusterConfigContext';
import AppSectionWrapper from '../../common/Layout/AppSectionWrapper';
import AdvisorResultsList from '../Clusters/Advisors/AdvisorResultsList';
import RunningOperationPage from './RunningOperationPage';

export default PerformancePage;

export enum PerformancePageSection {
    DB_GROWTH = 'db-growth',
    QUERY_MONITOR = 'query-monitor',
    AGENT_BASED_QUERY_MONITOR = 'agent-based-query-monitor',
    ADVISORS = 'advisors',
    RUNNING_OPERATIONS = 'running-operations',
}

export type PerformancePageProps = AppPageWrapperProps & {
    cluster: CcCluster;
};

function PerformancePage({ cluster, ...rest }: PerformancePageProps) {
    const navigate = useNavigate();
    const { section = PerformancePageSection.DB_GROWTH } = useParams<{
        section: PerformancePageSection;
    }>();
    const submenuItems = useMemo(
        () => [
            {
                label: 'DB Growth',
                key: PerformancePageSection.DB_GROWTH,
                children: <DbGrowthPage cluster={cluster} />,
            },
            ...([
                CcClusterTechnology.TECHNOLOGY_MYSQL,
                CcClusterTechnology.TECHNOLOGY_POSTGRESQL,
            ].includes(cluster.getTechnology())
                ? [
                      {
                          label: 'Query Monitor',
                          key: PerformancePageSection.QUERY_MONITOR,
                          children: <QueryMonitorPage cluster={cluster} />,
                      },
                      {
                          label: (
                              <BetaRibbon margin={5}>
                                  Query Monitor/Agents
                              </BetaRibbon>
                          ),
                          key: PerformancePageSection.AGENT_BASED_QUERY_MONITOR,
                          children: <AgentQueryMonitorPage cluster={cluster} />,
                      },
                  ]
                : []),
            {
                label: 'Advisors',
                key: PerformancePageSection.ADVISORS,
                children: <AdvisorResultsList cluster={cluster} />,
            },
            ...(cluster.getTechnology() ===
            CcClusterTechnology.TECHNOLOGY_MONGODB
                ? [
                      {
                          label: 'Running Operations',
                          key: PerformancePageSection.RUNNING_OPERATIONS,
                          children: <RunningOperationPage cluster={cluster} />,
                      },
                  ]
                : []),
        ],
        []
    );
    return (
        <AppPageWrapper className="PerformancePage" noPadding={true} {...rest}>
            <ClusterConfigProvider clusterId={cluster.clusterId}>
                <AppSectionWrapper
                    items={submenuItems}
                    activeSection={section}
                    onNavigate={(key) => {
                        navigate(
                            `/clusters/${cluster.clusterId}/performance/${key}`
                        );
                    }}
                />
            </ClusterConfigProvider>
        </AppPageWrapper>
    );
}
