import { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateAlarmStatsRecord,
    AppStateCurrentAlarms,
    setAlarmStats,
    setCurrentAlarms,
} from '../../appReducer';
import Immutable from 'immutable';
import { arrayFilter, arrayPages } from '../../common/filtering';
import useListFetch from '../../common/useListFetch';

import {
    initialListState,
    listReducer,
    setListAll,
} from '../../common/listReducer';
import { getSortAlphabeticFn } from '../../common/sorting';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import CcJob from '../../services/models/CcJob';
import CmonAlarmService from '../../services/cmon/CmonAlarmService';
import CcAlarm from '../../services/models/CcAlarm';
import CcAlarmStats, {
    AlarmsCountProps,
} from '../../services/models/CcAlarmStats';

type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & { arr?: any[] };

type RefreshFunctionParams = FetchRefreshFunctionParams;

export type UseCurrentAlarmsListProps = ListParams & {
    name?: string | null;
    useGlobalState?: boolean;
    useCache?: boolean;
};
export default function useCurrentAlarmsList({
    name,
    useGlobalState = true,
    useCache = false,
    filters,
    pageSize = 10,
}: UseCurrentAlarmsListProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: currentAlarms,
        refresh: refreshFetch,
        cancel,
    } = useListFetch<CcAlarm>({
        name,
        useCache,
        pageSize: 0,
        fetchFn: async (params, opts) => {
            const { alarms, total } = await CmonAlarmService.getAlarms(
                {
                    ...params,
                },
                opts
            );
            return {
                list: alarms || [],
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonAlarmService.cancelRequest(requestId);
        },
    });
    const [storedCurrentAlarms, storedAlarmStats]: [
        AppStateCurrentAlarms,
        AppStateAlarmStatsRecord
    ] = useSelector(({ currentAlarms, alarmStats }: AppState) => [
        currentAlarms,
        alarmStats,
    ]);
    const [statsRecord, setStatsRecord] = useState<AppStateAlarmStatsRecord>();
    const [total, setTotal] = useState<number>();
    const [list, setList] = useState<CcAlarm[]>(
        storedCurrentAlarms ? storedCurrentAlarms.toList().toArray() : []
    );
    const dispatch = useDispatch();
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        pageSize: pageSize,
        order: getSortAlphabeticFn('descend', (x) => x.created),
        filters: filters,
    });

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = (storedCurrentAlarms &&
                storedCurrentAlarms.toList().toArray()) ||
                [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            const filteredArr = arrayFilter({ filters, arr });
            setList(arrayPages({ page, pageSize, order, arr: filteredArr }));
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listPage, listPageSize, listOrder, listFilters, storedCurrentAlarms]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshFetch({ ...rest });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (currentAlarms) {
            const unmutedAlarms = currentAlarms.filter(
                getIgnoredAlarmsFilter()
            );
            const alarmsCount: AlarmsCountProps = unmutedAlarms.reduce(
                (accumulator: any, curr: CcAlarm) => {
                    if (!accumulator[curr.severityName]) {
                        accumulator[curr.severityName] = 0;
                    }
                    accumulator[curr.severityName] += 1;
                    return accumulator;
                },
                {}
            );

            const stats = new CcAlarmStats({ alarmsCount });
            setStatsRecord(stats);
            if (useGlobalState) {
                dispatch(setAlarmStats(stats));
                dispatch(
                    setCurrentAlarms(
                        Immutable.Map(
                            currentAlarms.map((c: CcAlarm) => [c.getKey(), c])
                        )
                    )
                );
            }

            filter({
                arr: unmutedAlarms,
            });
        }
    }, [currentAlarms]);

    useEffect(() => {
        if (useGlobalState && storedCurrentAlarms) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedCurrentAlarms]);

    useEffect(() => {
        if (useGlobalState && storedAlarmStats) {
            setStatsRecord(storedAlarmStats);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storedAlarmStats]);

    return {
        error,
        loading,
        loaded,
        statsRecord,
        list,
        refresh,
        total,
        filter,
        cancel,
        page: listPage,
        pageSize: listPageSize,
    };
}

export function getIgnoredAlarmsFilter() {
    return (a: CcAlarm) => !a.isIgnored();
}
